<template>
	<w-list max-width="100%">
		<EditCategory :action="action" :opened="opened" :value="categorySelected" @close="opened = false" />
		<v-subheader v-t="'offers.categories'" />
		<!-- Add category button -->
		<v-list-tile v-if="!readonly" color="primary" @click="createCategory">
			<v-tooltip v-if="mini" top>
				<template v-slot:activator="{ on }">
					<v-list-tile-avatar v-on="on">
						<v-btn color="primary" icon flat>
							<v-icon>add</v-icon>
						</v-btn>
					</v-list-tile-avatar>
				</template>
				<span>{{ $t('offers.create_category') }}</span>
			</v-tooltip>
			<v-list-tile-avatar v-if="!mini">
				<v-btn color="primary" icon flat>
					<v-icon>add</v-icon>
				</v-btn>
			</v-list-tile-avatar>
			<v-list-tile-content v-if="!mini">{{ $t('offers.create_category') }}</v-list-tile-content>
		</v-list-tile>
		<w-list-tile
			v-for="(category, index) in categories"
			:key="category.id"
			:value="selectedCategory && category.id === selectedCategory.id"
			@mouseover="hover = index"
			@mouseout="hover = null"
			@click="selectCategory(category)"
		>
			<w-list-tile-avatar style="position: relative">
				<!-- Category color -->
				<v-tooltip v-if="mini" top>
					<template v-slot:activator="{ on }">
						<v-icon :color="category.color" large v-on="on">brightness_1</v-icon>
						<span
							style="
								position: absolute;
								top: 50%;
								left: 50%;
								-moz-transform: translate(-50%, -50%);
								transform: translate(-50%, -50%);
								color: white;
								font-size: 0.8em;
								font-weight: bold;
							"
							v-on="on"
							v-text="category.abbreviation"
						/>
					</template>
					<span v-text="category.name" />
				</v-tooltip>
				<v-icon v-if="!mini" :color="category.color" large>brightness_1</v-icon>
				<span
					v-if="!mini"
					style="
						position: absolute;
						top: 50%;
						left: 50%;
						-moz-transform: translate(-50%, -50%);
						transform: translate(-50%, -50%);
						color: white;
						font-size: 0.8em;
						font-weight: bold;
					"
					v-text="category.abbreviation"
				/>
			</w-list-tile-avatar>
			<!-- Category name -->
			<v-list-tile-content v-if="!mini" class="text-truncate" v-text="category.name"></v-list-tile-content>
			<!-- Category actions -->
			<v-fade-transition v-if="!mini">
				<v-list-tile-avatar v-if="hover === index && !readonly" right>
					<!-- ::Update -->
					<v-tooltip top>
						<template v-slot:activator="{ on }">
							<v-btn icon class="mx-0 px-0 right" v-on="on" @click.stop="updateCategory(category)">
								<v-icon color="primary">settings</v-icon>
							</v-btn>
						</template>
						<span>{{ $t('offers.update_category') }}</span>
					</v-tooltip>
					<!-- ::Delete -->
					<v-tooltip top>
						<template v-slot:activator="{ on }">
							<v-btn icon class="mx-0 px-0 right" v-on="on" @click.stop="deleteCategory(category)">
								<v-icon color="primary">delete</v-icon>
							</v-btn>
						</template>
						<span>{{ $t('offers.delete_category') }}</span>
					</v-tooltip>
				</v-list-tile-avatar>
			</v-fade-transition>
		</w-list-tile>

		<!-- Loader -->
		<v-list-tile v-if="loading">
			<v-list-tile-action>
				<v-progress-circular color="primary" indeterminate />
			</v-list-tile-action>
			<v-list-tile-title v-t="'loading_progress'" />
		</v-list-tile>
	</w-list>
</template>

<script>
import { mapState } from 'vuex'

import OffersModuleGuard from '@/mixins/ModulesGuards/Offers/OffersModuleGuard'

export default {
	name: 'ListOfCategories',
	components: {
		EditCategory: () => ({
			component: import('@/components/Offers/EditCategory')
		})
	},
	mixins: [OffersModuleGuard],
	props: {
		loading: {
			default: () => false,
			required: false,
			type: Boolean
		},
		mini: {
			default: () => false,
			required: false,
			type: Boolean
		},
		readonly: {
			default: () => false,
			required: false,
			type: Boolean
		}
	},
	data: function () {
		return {
			action: 'create',
			categorySelected: {},
			hover: null,
			opened: false
		}
	},
	computed: {
		...mapState({
			categories: state => state.offers.categories,
			selectedCategory: state => state.offers.category
		})
	},
	methods: {
		createCategory: function () {
			this.action = 'create'
			this.categorySelected = {}
			this.opened = true
		},
		deleteCategory: function (currentCategory) {
			this.action = 'delete'
			this.$dialog
				.warning({
					text: this.$t('actions.are_you_sure'),
					title: this.$t('offers.delete_category'),
					actions: {
						false: this.$t('actions.no'),
						true: this.$t('actions.yes')
					}
				})
				.then(res => {
					if (res) {
						this.service.deleteCategory(this.accountingFirmId, currentCategory.id)
					}
				})
		},
		selectCategory: function (category) {
			this.service.selectCategory(category.id)
		},
		updateCategory: function (categoryToUpdate) {
			this.action = 'update'
			this.categorySelected = { ...categoryToUpdate }
			this.opened = true
		}
	}
}
</script>
